import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as CopyIcon } from "./icons/copy.svg";
import { ReactComponent as SendIcon } from "./icons/send-down.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from 'copy-to-clipboard';
import { nanoid } from "nanoid";
import useLocalStorageState from "use-local-storage-state";
import RandExp from "randexp";
import { NonLatin } from "./Components/NonLatin";

const getLink = (email) => {
  const {
    groups: { username, domain },
  } = /^(?<username>[\w.]+)@(?<domain>[\w.]+)$/.exec(email) || { groups: {} };
  return username && domain
    ? `https://emailfake.com/${domain}/${username}`
    : null;
};

const ResolveMailLink = () => {
  const [email, setEmail] = useState("");
  const link = getLink(email);
  return (
    <div>
      <h3>Resolve Mail Link</h3>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <span>
        {link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        ) : null}
      </span>
    </div>
  );
};


function generateRandomEmail(emailProvider) {
  const firstName = ['John', 'Jane', 'Bob', 'Alice', 'Chris', 'David', 'Emily', 'Michael', 'Jennifer', 'Sarah', 'Robert', 'Daniel'];
  const lastName = ['Smith', 'Johnson', 'Williams', 'Jones', 'Brown', 'Davis', 'Garcia', 'Miller', 'Rodriguez', 'Gonzalez', 'Wilson', 'Martinez'];
  const randomFirstName = firstName[Math.floor(Math.random() * firstName.length)];
  const randomLastName = lastName[Math.floor(Math.random() * lastName.length)];
  const randomProvider = emailProvider[Math.floor(Math.random() * emailProvider.length)];
  const randomNum = Math.floor(Math.random() * 99) + 1;
  const randomEmail = `${randomFirstName.toLowerCase()}.${randomLastName.toLowerCase()}${randomNum}@${randomProvider}`;
  return randomEmail;
}

const RandomMail = ({ num }) => {
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useLocalStorageState(`domain${num}`, "");
  const newRandomMail = useCallback((copyToClipboard) => {
    let newEmail;
    if((num??0) < 1) {
      
      newEmail = `${(nanoid(11).toLowerCase().replace(/[\W_]/, ""))}@${domain}`;
    } else {
      newEmail = generateRandomEmail(domain?.split(','))
    }
    setEmail(newEmail);
    if(copyToClipboard) {
      copy(`${newEmail}`);
    }
  },[domain, num]);
  useEffect(() => {
    newRandomMail();
  }, [newRandomMail]);
  return (
    <div>
      <h3>
        Random Mail {num} <button onClick={() => newRandomMail(true)}>New & Copy</button>
      </h3>
      <div>
        Domain:
        <input
          value={domain}
          onChange={(event) => setDomain(event.target.value)}
        />
      </div>
      <input
        type="text"
        value={`${email}`}
        onChange={(e) => setEmail(e.target.value)}
        readOnly={true}
      />
      <span>
        <CopyToClipboard text={`${email}`}>
          <button>
            <CopyIcon width="24" height="24" />
          </button>
        </CopyToClipboard>
      </span>
    </div>
  );
};
const RandomText = ({ num, sendText }) => {
  const [text, setText] = useState("");
  const [expText, setExpText] = useLocalStorageState(`randexp${num}`, "");
  const newRandomText = (exp, copyToClipboard) => {
    try {
      let newText = new RandExp(exp).gen();
      setText(newText);
      if(copyToClipboard) {
        copy(newText)
      }
    } catch (e) {
      setText(e);
    }
  };
  useEffect(() => {
    newRandomText(expText);
  }, [expText]);
  return (
    <div>
      <h3>
        Random Text {num}{" "}
        <button onClick={() => newRandomText(expText, true)}>New & Copy</button>
      </h3>
      <div>
        RandExp:
        <input
          value={expText}
          onChange={(event) => setExpText(event.target.value)}
          size="120"
        />
      </div>
      <input type="text" value={`${text}`} readOnly={true} size="120" />
      <span>
        <CopyToClipboard text={`${text}`}>
          <button>
            <CopyIcon width="24" height="24" />
          </button>
        </CopyToClipboard>
        <button onClick={() => sendText(text)}>
          <SendIcon width="24" height="24" />
        </button>
      </span>
    </div>
  );
};

function App() {
  const [text, setText] = useState();

  

  return (
    <div>
      <ResolveMailLink />
      <RandomMail />
      <RandomMail num={2} />
      <RandomText sendText={setText} />
      <RandomText num={2} sendText={setText} />
      <RandomText num={3} sendText={setText} />
      <RandomText num={4} sendText={setText} />
      <NonLatin text={text} setText={setText} />
      <hr />
      {text}
    </div>
  );
}

export default App;
