import React from "react";
import Highlighter from "react-highlighter";

export const NonLatin = ({ text, setText }) => {

  // eslint-disable-next-line no-control-regex
  const nonLatinRe = /[^\u0000-\u007f]+/;

  return (
    <div>
      <h3>Check non-latin text</h3>
      <div>
        <textarea onChange={(event) => setText(event.target.value)} value={text}>

        </textarea>{text}
      </div>
      <div>123
        <Highlighter search={nonLatinRe}>{text}</Highlighter>
      </div>
    </div>
  );
};
